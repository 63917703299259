import { getActivitiesTime } from '~/services/cfg/models/Dev'
import { Sprint } from './Sprint'

type StatsInputHolder = Pick<
  Sprint,
  'devStats' | 'inaccurateIssuesWeight' | 'bugsReportedByPos'
>

export class AggregateStats {
  // having an input holder instead of separate props allows to receive a Sprint instance
  // and be reactive to its changes
  constructor(private statsInputHolder: StatsInputHolder) {}

  private get stats() {
    return this.statsInputHolder.devStats
  }

  private get inaccurateIssues() {
    return this.statsInputHolder.inaccurateIssuesWeight
  }

  private get bugs() {
    return this.statsInputHolder.bugsReportedByPos
  }

  // #region spent time
  get untrackedTime() {
    return this.stats.sum((x) => x.spent.untracked)
  }
  get nonDevSpent() {
    return this.allNonDevSpent.reduce((acc, activitiesTime) => {
      entries(activitiesTime).forEach(([activity, time]) => {
        acc[activity] += time
      })
      return acc
    }, getActivitiesTime([]))
  }
  get nonDevSpentPerc() {
    return mapValues(
      (x) => ((x ?? 0) * 100) / this.sprintAvailableTime,
      this.nonDevSpent,
    )
  }
  get totalTrackedSpent() {
    return this.stats.sum((x) => x.spent.totalTracked)
  }
  get totalDevSpent() {
    return this.stats.sum((x) => x.spent.dev)
  }
  get devDeliveredSpent() {
    return this.stats.sum((x) => x.spent.devDelivered)
  }
  get lostSpent() {
    return this.totalDevSpent - this.devDeliveredSpent
  }
  // #endregion
  get fastTrackDelivered() {
    return this.stats.sum((x) => x.fastTrackDelivered)
  }

  get devRAndDSpentMd() {
    return this.stats.sum((x) => x.devRAndDSpentMd)
  }

  get rAndDAndLearningMd() {
    return this.devRAndDSpentMd + this.nonDevSpent['R&D']
  }

  get delivered() {
    return this.stats.sum((x) => x.delivered)
  }
  /**
   * total MD spent on budget issues
   */
  get budgetMD() {
    return this.stats.sum((x) => x.budgetMD)
  }
  /**
   * @deprecated
   */
  get pf() {
    return this.stats.sum((x) => x.pf)
  }
  /**
   * @deprecated
   */
  get velocity() {
    return this.delivered / this.pf
  }
  get absInaccuracy() {
    return this.stats
      .flatMap((x) => x.inaccurateIssues)
      .distinct()
      .sum((issueId) => this.inaccurateIssues[issueId]!)
  }
  get inaccuracy() {
    return this.absInaccuracy / this.delivered
  }
  get deliveredAbs() {
    return this.stats.sum((x) => x.deliveredAbs)
  }
  /**
   * delivered without budget issues and
   * taking into account fast track bonus
   */
  get deliveredForDevEff() {
    return this.stats.sum((x) => x.deliveredForDevEff)
  }
  // get idealDevOutput() {
  //   return this.stats.sum((x) => x.idealDevOutput)
  // }
  get idealDevOutputNoBudget() {
    return this.stats.sum((x) => x.idealDevOutputNoBudget)
  }
  get workingDays() {
    return this.stats.sum((x) => x.workingDays)
  }
  /**
   * @deprecated
   */
  get devTimePercOld() {
    return (this.totalDevSpent * 100) / this.workingDays
  }
  get devEffectiveness() {
    return (this.deliveredForDevEff * 100) / this.idealDevOutputNoBudget
  }
  get allNonDevSpent() {
    return this.stats.flatMap((x) => x.spent.nonDev)
  }
  get idealOutput() {
    return this.stats.sum((x) => x.idealOutput)
  }
  get effectiveness() {
    return (this.deliveredAbs * 100) / this.idealOutput
  }
  get perfRatios() {
    return this.stats.flatMap((x) => x.sprint.perfRatio).distinct()
  }
  /**
   * when stats coming from sprints with different perf ratios are aggregated, 0 is returned
   */
  get perfRatio() {
    return this.perfRatios.length === 1 ? this.perfRatios[0]! : 0
  }
  get sprintAvailableTime() {
    return this.totalTrackedSpent - this.nonDevSpent.off
  }
  get devTimePerc() {
    return (this.totalDevSpent * 100) / this.sprintAvailableTime
  }
  get nonDevTimeLog() {
    return this.stats.flatMap(({ dev, nonDevTimeLog }) =>
      !!dev ? nonDevTimeLog.map((log) => ({ ...log, author: dev })) : [],
    )
  }
  get periodStart() {
    return this.stats
      .map((s) => s.sprint.firstDay)
      .firstBy((a, b) => (!a || !b ? false : a.isBefore(b)))
  }
  get periodEnd() {
    return this.stats
      .map((s) => s.sprint.lastDay)
      .firstBy((a, b) => (!a || !b ? false : b.isBefore(a)))
  }
  get totBugCount() {
    return this.bugs.length
  }
  get lowPrioBugCount() {
    return this.bugs.filter((b) => b.priority === 'Low').length
  }
  get hiPrioBugCount() {
    return this.bugs.filter((b) => b.priority === 'High').length
  }
  get midPrioBugCount() {
    return this.totBugCount - this.lowPrioBugCount - this.hiPrioBugCount
  }
}

export const AggregateStatsR = reactifyClass(AggregateStats)
