import type { TFilter } from '~/components/dnd/filters'
export { pushWithState } from './3.queryString/utils'
import { buildUseQsStorageRef, attachRouterHooks } from './3.queryString/utils'

/**
 * SYNCED REF DEFINITIONS
 * 1. create wrapper and ref getter through `buildUseQsStorageRef`
 * 2. export the ref getter
 * 3. add the wrapper to the `observedRoutes` array
 */

const planningFilters = buildUseQsStorageRef(
  'planning',
  'planningFilters',
  () => ({}) as TFilter,
)
export const usePlanningFilters = planningFilters.useRef

const planningHiddenColumns = buildUseQsStorageRef(
  'planning',
  'planningHiddenColumns',
  () => [] as string[],
)
export const usePlanningHiddenColumns = planningHiddenColumns.useRef

const seedsFilters = buildUseQsStorageRef(
  'seeds',
  'seedsFilters',
  () => ({}) as TFilter,
)
export const useSeedsFilters = seedsFilters.useRef

const seedsHiddenColumns = buildUseQsStorageRef(
  'seeds',
  'seedsHiddenColumns',
  () => [] as string[],
)
export const useSeedsHiddenColumns = seedsHiddenColumns.useRef

/**
 * This object need to be defined at app boot to make the QS/storage init/sync work before
 * the ref is actually requested and used.
 */
const observedRoutes = [
  planningFilters.wrapper,
  planningHiddenColumns.wrapper,
  seedsFilters.wrapper,
  seedsHiddenColumns.wrapper,
] as const

/**
 * SYNCED REF DEFINITIONS END
 */

export default defineNuxtPlugin((nuxt) => {
  attachRouterHooks(useRouter(), observedRoutes)
})

export type ObservedRoutes = typeof observedRoutes
