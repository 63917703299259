import { Sprint } from './Sprint'

export const LoadStatsR = reactifyClass(
  class {
    constructor(
      private sprint: Sprint,
      private teamName: TeamName,
      private domain?: Domain,
    ) {}
    private get team() {
      return this.sprint.teams?.[this.teamName]
    }
    private get devs() {
      return this.team?.[this.domain === 'be' ? 'BErs' : 'FErs']
    }
    private get issues() {
      const issues =
        this.sprint.issues?.map((i) => ({ issue: i, nth: i.isNth })) ?? []
      const candidates = this.sprint.issueCandidates ?? []
      return [...issues, ...candidates].filter(
        (i) => i.issue.teamName === this.teamName,
      )
    }
    private get mandatoryIssues() {
      return this.issues.filter((i) => !i.nth).map((i) => i.issue)
    }
    private get nthIssues() {
      return this.issues.filter((i) => i.nth).map((i) => i.issue)
    }
    get off() {
      return this.devs?.sum((dev) => dev.daysOff) ?? 0
    }
    get PF() {
      return this.sprint.sprintLength * (this.devs?.length ?? 0) - this.off
    }
    get velocity() {
      return (
        this.sprint.velocityForecastAggregates?.[this.teamName][
          this.domain ?? 'all'
        ] ?? 0
      )
    }
    /**
     * Forecast is computed by domain only!
     * Sum of all domains aggregate gives the total forecast based on the sprint's team velocity,
     * so for no-domain aggregate, since it is meaningless, it's always 0 and doesn't affect the total forecast.
     */
    get forecast() {
      if (!this.domain) return 0
      return this.velocity * this.PF
    }
    // TODO: redundant getters! (see buildStatsAggregate)
    get loadStatus() {
      const loadPerc = this.percLoadOnForecast
      return loadPerc > 120
        ? 'over'
        : loadPerc > 100
        ? 'slightlyOver'
        : loadPerc > 80
        ? 'slightlyUnder'
        : 'under'
    }
    get mandatoryLoad() {
      return this.mandatoryIssues.sum(
        (i) => i.stats[this.domain ?? 'noDomain'].weight ?? 0,
      )
    }
    get nthLoad() {
      return this.nthIssues.sum(
        (i) => i.stats[this.domain ?? 'noDomain'].weight ?? 0,
      )
    }
    get totLoad() {
      return this.mandatoryLoad + this.nthLoad
    }
    get nthLoadPerc() {
      return (this.nthLoad * 100) / this.totLoad
    }
    get percLoadOnForecast() {
      return (this.mandatoryLoad * 100) / this.forecast
    }
  },
)

export const buildStatsAggregate = (
  stats: Pick<
    ReturnType<typeof LoadStatsR>,
    'mandatoryLoad' | 'nthLoad' | 'totLoad' | 'PF' | 'forecast' | 'off'
  >[],
) =>
  reactify({
    get off() {
      return stats.sum((team) => team.off)
    },
    get PF() {
      return stats.sum((team) => team.PF)
    },
    get forecast() {
      return stats.sum((team) => team.forecast)
    },
    get velocity() {
      if (this.PF === 0) return 0
      return this.forecast / this.PF
    },
    get mandatoryLoad() {
      return stats.sum((team) => team.mandatoryLoad)
    },
    get nthLoad() {
      return stats.sum((team) => team.nthLoad)
    },
    get percLoadOnForecast() {
      return (this.mandatoryLoad * 100) / this.forecast
    },
    get loadStatus() {
      const loadPerc = this.percLoadOnForecast
      return loadPerc > 120
        ? 'over'
        : loadPerc > 100
        ? 'slightlyOver'
        : loadPerc > 80
        ? 'slightlyUnder'
        : 'under'
    },
    get totLoad() {
      return stats.sum((team) => team.totLoad)
    },
    get nthLoadPerc() {
      return (this.nthLoad * 100) / this.totLoad
    },
  })
