export const KSCRUM_LABELS = {
  system: 'kscrum::system',
  seeds: 'kscrum::seeds',
} as const

const kscrumLabelValues = Object.values(KSCRUM_LABELS) as string[]

export const isKScrumLabel = (label: string) =>
  kscrumLabelValues.includes(label)

export const LABELS = {
  ...KSCRUM_LABELS,
  intEfficiency: 'cluster::intEfficiency',
  custExperience: 'cluster::custExperience',
  techOrOther: 'cluster::techOrOther',
  fastTrack: 'Fast Track',
  budget: 'budget',
  blocked: 'BLOCKED',
  underDefinition: 'UNDER DEFINITION',
  defined: 'DEFINED',
  exploration: 'EXPLORATION',
  roadmapReady: 'ROADMAP READY',
  missingTest1: 'missingTest::light',
  missingTest2: 'missingTest::medium',
  missingTest3: 'missingTest::serious',
  feAnalysis: 'FE ANALYSIS',
  beAnalysis: 'BE ANALYSIS',
  feWorkable: 'FE WORKABLE',
  beWorkable: 'BE WORKABLE',
  beOnly: 'BE ONLY',
  feOnly: 'FE ONLY',
  backlog: 'BACKLOG',
  design: 'SOLUTION DESIGN',
  bugfix: 'bug/fix',
  techDebt: 'tech debt',
  RAndD: 'Tech Friday',
  lowPrio: 'priority::low',
  midPrio: 'priority::medium',
  hiPrio: 'priority::high',
  analysis: 'ANALYSIS',
  workable: 'workable',
  todo: 'TODO',
  wip: 'WIP',
  nth: 'NTH',
  documentation: 'DOCUMENTATION',
  codeReview: 'CODE REVIEW',
  inStaging: 'IN STAGING',
  review: 'REVIEW',
  readyForStaging: 'READY FOR DEPLOY IN STAGING',
  readyForProd: 'READY FOR DEPLOY IN PROD',
  inProd: 'IN PROD',
} as const

type LabelsKey = keyof typeof LABELS
export type LabelsValue = (typeof LABELS)[LabelsKey]
