import { glGetAll } from '~/api/common'
import { CFG, allDevs } from '~/services/cfg'
import { createMilestone, milestoneIsSprint, milestoneIsUnplanned } from '..'
import { Sprint, SprintR } from './Sprint'
import { issuesHub } from '~/api/issues/models/IssueHub'
import { toast } from 'vuetify-sonner'
import { createIssue } from '~/api/issues'
import {
  SprintIssue,
  SprintIssueR,
  summaryToDescription,
} from '~/api/issues/models'
import { DevVelocityR } from './forecast/DevVelocity'
import { DomainVelocityR } from './forecast/DomainVelocity'
import { sprintCandidates } from '~/api/issues/models/specialIssues/SprintCandidates'
import { sprintResults } from '~/api/issues/models/specialIssues/SprintResults'
import { sprintCfg } from '~/api/issues/models/specialIssues/SprintCfg'
import { planningOrder } from '~/api/issues/models/specialIssues/PlanningOrder'

// sprints before this number have invalid data (no results, team composition with undefined ids, etc)
const FIRST_SPRINT_VISIBLE = 12

const loadMilestones = singletonFactory(async () => {
  const milestones = await glGetAll<GLMilestone[]>(
    CFG.projects.general.id.toString(),
    `milestones`,
  )
  return milestones
})

const loadSprints = singletonFactory(async () =>
  (await loadMilestones())
    .filter(
      (s) => milestoneIsSprint(s) && Number(s.title) >= FIRST_SPRINT_VISIBLE,
    )
    .map((m) => SprintR(m))
    .sort((a, b) => a.number - b.number),
)

const loadUnplanned = singletonFactory(async () =>
  (await loadMilestones())
    .filter(milestoneIsUnplanned)
    .map((m) => SprintR(m, true))
    .sort((a, b) => a.number - b.number),
)

/**
 * root node:
 * handles planning and sprints
 */
class SprintsHub {
  private _sprints?: Sprint[]
  private _unplanned?: Sprint[]

  /**
   * loads all sprints + cfgs and results
   * @returns
   */
  async loadSprints() {
    const [sprints] = await Promise.all([
      loadSprints(),
      sprintResults.load(),
      sprintCfg.load(),
    ])
    if (!this._sprints) this._sprints = sprints
    return this._sprints
  }

  async loadUnplanned() {
    const unplanned = await loadUnplanned()
    if (!this._unplanned) this._unplanned = unplanned
    return this._unplanned
  }

  async createSprint(teams: TeamsCfg, sprintDates: DayJs[]) {
    if (sprintDates.length < 1) {
      toast.error('Sprint must have at least 1 day')
    }
    sprintDates = sprintDates.sort((a, b) => a.diff(b))

    const startDate = sprintDates[0]!
    const endDate = sprintDates[sprintDates.length - 1]!

    const [milestone] = await Promise.all([
      createMilestone({
        title: this.nextSprintNumber!.toString(),
        project_id: CFG.projects.general.id.toString(),
        start_date: startDate.toISOString().substring(0, 10),
        due_date: endDate!.toISOString().substring(0, 10),
      }),
      sprintCfg.saveSprintCfg(this.nextSprintNumber!, teams, sprintDates),
    ])
    const newSprint = SprintR(milestone)
    this._sprints!.push(newSprint)
  }

  get sprints() {
    // side effect implicit load
    this.loadSprints()
    return this._sprints?.sort((a, b) => a.number - b.number)
  }

  get unplanned() {
    // side effect implicit load
    this.loadUnplanned()
    return this._unplanned
  }

  get sprintsAndUnplanned() {
    const { sprints, unplanned } = this
    if (!sprints || !unplanned) return undefined
    return sprints.concat(unplanned)
  }

  /**
   * sprints with results
   */
  get closedSprints() {
    return this.sprints?.filter((s) => s.hasResults)
  }

  /**
   * sprints without results
   */
  get openSprints() {
    return this.sprints?.filter((s) => !s.hasResults)
  }

  get currentSprint() {
    return this.openSprints?.first()
  }

  // #region planning

  private _orderedIids?: number[]
  /**
   * open sprints and unplanned
   */
  private async loadPlanningOrder() {
    const order = await planningOrder.load()
    if (!this._orderedIids) this._orderedIids = order
  }
  get planningSprints() {
    if (!!this.openSprints && !!this.unplanned) {
      return [...this.openSprints, ...this.unplanned]
    }
  }

  get planningIssues() {
    const planningSprints = this.planningSprints

    if (!!planningSprints) {
      const missing = planningSprints.find((s) => !s.issues)
      console.log('>>>> missing issues', missing?.number)
      // ;(window as any).missing = missing
      ;(window as any).sprintsHub = this
    }

    if (!this._orderedIids) {
      this.loadPlanningOrder()
    } else if (!!planningSprints && planningSprints.every((s) => !!s.issues)) {
      return planningSprints
        .flatMap((s) => s.issues!)
        .orderByIid(this._orderedIids)
    }
  }

  async movePlanningIssue(moveFrom: SprintIssue, moveTo: SprintIssue) {
    if (!this._orderedIids || !this.planningIssues)
      throw new Error('ordered iids or planning issues not loaded')
    if (moveFrom === moveTo) return
    const order = this.planningIssues.map((issue) => issue.iid)
    const moveFromIndex = order.indexOf(moveFrom.iid)
    const moveToIndex = order.indexOf(moveTo.iid)
    order.move(moveFromIndex, moveToIndex)

    if (order.equals(this._orderedIids)) return

    // optimistic update
    this._orderedIids = order
    const updated = await planningOrder.save(order)
    this._orderedIids = updated
  }
  // #endregion

  get nextSprintNumber() {
    return this.sprints!.last()!.number + 1
  }

  get sprintsCount() {
    if (!!this._sprints) {
      return this._sprints.length
    }
  }

  /**
   * all issues in past sprints (closed sprints with results)
   */
  get pastIssues() {
    return this.closedSprints?.flatMap((s) => s.issues ?? [])
  }

  /**
   * all issues in the current sprint
   */
  get currentSprintIssues() {
    return this.currentSprint?.issues
  }

  /**
   * all issues in future sprints (open sprints and unplanned excluding the current one)
   */
  get futureIssues() {
    if (!this.planningSprints || !this.currentSprint) return
    return this.planningSprints
      .removeI(this.currentSprint)
      .flatMap((s) => s.issues ?? [])
  }

  /**
   * all issues in all sprints
   */
  get allIssues() {
    if (!!this.pastIssues && !!this.futureIssues && !!this.currentSprintIssues)
      return [
        ...this.pastIssues,
        ...this.futureIssues,
        ...this.currentSprintIssues,
      ]
  }

  /**
   * when true, the forecast will include open sprints (current one) in stats
   */
  public includeCurrentSprintInForecast = false

  avgVelocity = {
    be: DomainVelocityR('be', false),
    fe: DomainVelocityR('fe', false),
  }

  avgVelocityIncludingCurrent = {
    be: DomainVelocityR('be', true),
    fe: DomainVelocityR('fe', true),
  }

  devVelocityNoCurrent = allDevs.map((dev) => DevVelocityR(dev, false))
  devVelocityIncludingCurrent = allDevs.map((dev) => DevVelocityR(dev, true))

  /**
   * devs velocity considering the includeCurrentSprintInForecast flag
   */
  get devVelocity() {
    return this.includeCurrentSprintInForecast
      ? this.devVelocityIncludingCurrent
      : this.devVelocityNoCurrent
  }

  /**
   * assigns an unassigned issue to a sprint
   * and takes care of updating "unassigned" and "sprint.issues" collections
   * @param issueIid
   * @param sprintNumber
   */
  async assignToSprint(issueIid: number, sprintNumber: number) {
    if (!this.sprintsAndUnplanned)
      throw new Error('Sprints or Unplanned not loaded')
    await issuesHub.loadUnassigned()
    if (!issuesHub.unassignedIssues)
      throw new Error('Unassigned issues not loaded')

    const targetSprint = this.sprintsAndUnplanned.find(
      (s) => s.number === sprintNumber,
    )
    if (!targetSprint || !targetSprint.issues)
      throw new Error('Sprint or Issues not loaded')

    const unassignedIssue = issuesHub.unassignedIssues.findByIid(issueIid)
    if (!unassignedIssue)
      throw new Error(`Unassigned Issue ${issueIid} not found`)

    await unassignedIssue.assignSprint(targetSprint)
    issuesHub.removeUnassigned(unassignedIssue)
    targetSprint.issues.push(unassignedIssue.toSprintIssue(targetSprint))
  }

  /**
   * Assigns an already assigned issue to a different sprint and takes care of updating "sprint.issues" collections
   * or unassigns an issue from a sprint and takes care of updating "sprint.issues" and "unassigned" collections.
   * In case of unassignment, if the issue is a candidate for a future sprint, it will not be unassigned, but
   * reassigned to the future sprint.
   * Possible candidacy is removed in any case.
   * @param sprintIssue
   * @param srcSprintNumber
   * @param targetSprintNumber
   */
  async setSprintAssignment(sprintIssue: SprintIssue, targetSprint?: Sprint) {
    if (!this.sprintsAndUnplanned)
      throw new Error('Sprints and Unplanned not loaded')

    const srcSprint = sprintIssue.sprint
    sprintIssue.candidate?.sprint

    // if a candidacy is present, we do not unassign, but reassign to the future sprint
    targetSprint = targetSprint ?? sprintIssue.candidate?.sprint

    if (!targetSprint) {
      // unassignment case: unassign from sprint and add to unassigned issues
      const issue = await sprintIssue.unassignSprint()
      sprintIssue.sprint.issues!.remove(sprintIssue)
      issuesHub.addUnassigned(issue)
    } else {
      // reassignment case: if issue is already assigned to a sprint, assign it to the new sprint,
      // remove it from the old sprint and add it to the new sprint collection
      if (!targetSprint.issues)
        throw new Error(
          `Target sprint #${targetSprint.number} issues not loaded`,
        )
      await sprintIssue.assignSprint(targetSprint)
      targetSprint.issues.push(sprintIssue)
      srcSprint.issues?.remove(sprintIssue)
    }

    // always remove candidacy if any
    await sprintCandidates.setAssignation(sprintIssue.iid)
  }

  async addNewIssueToUnallocated(
    title: string,
    summary: string,
    teamName?: TeamName,
  ) {
    const unplanned = await this.loadUnplanned()
    const sprint = unplanned.last()!
    const issues = await sprint.loadIssues()
    const issue = await createIssue(CFG.projects.general.id, title, {
      milestoneId: sprint.id,
      description: summaryToDescription(summary),
      ...(teamName ? { labels: [CFG.teams[teamName].glLabel] } : {}),
    })
    const sprintIssue = SprintIssueR(issue, sprint)
    issues.push(sprintIssue)
    return sprintIssue
  }
}

export const sprintsHub = reactifyClass(SprintsHub)()
