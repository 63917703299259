type ChoiceId = string
type Choice = {
  label: string
  id: ChoiceId
}

type TDialog = {
  text: string
  choices?: Choice[]
}

const defaultDialog: TDialog = {
  text: 'are you sure?',
}

export const dialogSetup = ref<TDialog | undefined>(undefined)

let resolvePromise: (value: ChoiceId) => void

export const close = (answer: ChoiceId) => {
  resolvePromise(answer)
  dialogSetup.value = undefined
}

export const getBoolConfirmation = (dialog = defaultDialog) => {
  dialogSetup.value = dialog
  return new Promise<boolean>((resolve) => {
    resolvePromise = (value: ChoiceId) => resolve(value === 'true')
  })
}

/**
 * asks the user for a choice or to cancel the action.
 * @param dialog
 * @returns 'false' if action has been cancelled, a choice id otherwise
 */
export const getConfirmation = (text: string, ...choices: Choice[]) => {
  dialogSetup.value = {
    text,
    choices,
  }
  return new Promise<ChoiceId>((resolve) => {
    resolvePromise = resolve
  })
}
