/**
 * @file utility functions as syntactic sugar or to empower refs addressing common use cases
 */

export type RefPlugin<T> = {
  /**
   * the hook is triggered when the value changes (even for the initialization)
   * @param value
   * @param isInit true if the change is due to initialization
   * @returns
   */
  onChange: (value: T, isInit: boolean) => void
}

/**
 * creates a ref and whatches for any change (deep!) that is transmitted to the plugins
 * @param getInitialValue returns the initial value the ref should have
 * @param plugins
 * @returns
 */
export const withPlugins = <T>(
  getInitialValue: () => T,
  ...plugins: RefPlugin<T>[]
) => {
  const initialValue = getInitialValue()
  const r = ref<T>(initialValue)
  watch(
    r,
    (value) => {
      plugins.forEach((plugin) => plugin.onChange(value as T, false))
    },
    { deep: true },
  )
  plugins.forEach((plugin) => plugin.onChange(initialValue, true))
  return r as Ref<T>
}

type WatchParams = Parameters<typeof watch>[2]

type UseTrack = {
  <T, R>(
    targetRef: Ref<T> | (() => T),
    builder: (arg: T, isInitialized: boolean) => R,
    watchOptions?: WatchParams,
  ): Ref<R>
  // TODO: try to use following function overload to watch multiple refs
  // see vue's typing => https://github.com/vue-reactivity/watch/blob/master/src/index.ts

  // <T extends Ref<unknown>[], R>(
  //   targetRef: T,
  //   builder: (arg: T, isInitialized: boolean) => R,
  //   watchOptions?: WatchParams,
  // ): Ref<R>
}

type MapSources<T> = {
  [K in keyof T]: T[K] extends Ref<infer U> ? U : never
}

type WatchSource<T = any> = Ref<T> | ComputedRef<T> | (() => T)

/**
 *
 * @param targetRef
 * @param builder
 * @param watchOptions
 * @returns
 */
export const useTrack: UseTrack = (
  targetRef,
  builder,
  watchOptions = { deep: true },
) => {
  const cloned = (
    isRef(targetRef)
      ? ref(builder(targetRef.value, false))
      : ref(builder(targetRef(), false))
  ) as Ref<ReturnType<typeof builder>> // https://stackoverflow.com/a/69901745
  watch(
    targetRef,
    (item) => {
      cloned.value = builder(item, true)
    },
    watchOptions,
  )
  return cloned
}

/**
 *
 * @param targetRef
 * @param builder
 * @param watchOptions
 * @returns
 */
export const useTracks = <T extends Ref<unknown>[], R>(
  targetRef: [...T],
  builder: (arg: MapSources<T>, isInitialized: boolean) => R,
  watchOptions: WatchParams = { deep: true },
) => {
  // TODO: fix casting
  const cloned = ref(
    builder(targetRef.map((r) => r.value) as any, false),
  ) as Ref<R> // https://stackoverflow.com/a/69901745
  watch(
    targetRef,
    (item: any) => {
      // TODO: fix casting
      cloned.value = builder(item, true)
    },
    watchOptions,
  )
  return cloned
}

/**
 *
 * @param targetRef
 * @param watchOptions
 * @returns
 */
export const useTrackClone = <T>(
  targetRef: Ref<T> | (() => T),
  watchOptions: WatchParams = { deep: true },
) => useTrack(targetRef, cloneDeep, watchOptions)
