/**
 * @file methods to simplify the access to local storage and reduce naming conflicts
 */
import { type OAuth2Token } from '@badgateway/oauth2-client'
import { type KeyName } from './keys'
export { type KeyName } from './keys'

/**
 * Creates a storage object that is stored and synchronized with local storage.
 * NOTES:
 * - all localStorage access R/W should be done through this function
 * - in order to avoid conflicts, the keyName should be unique: add a new key to KEYS when adding a new storage!
 *
 * @param key
 * @param getDefaultValue should return the default value of the storage if it is not set
 * @param parse defaults to JSON.parse
 * @param write defaults to JSON.stringify
 * @returns
 */
export const getStorage = <T>(
  key: KeyName,
  getDefaultValue: () => T,
  parse: (serialized: string) => T = (serialized) => JSON.parse(serialized),
  write: (value: T) => string = (value) => JSON.stringify(value),
) => ({
  get: () => {
    const value = localStorage.getItem(key)
    return value !== null ? parse(value) : getDefaultValue()
  },
  set: (value: T) => {
    localStorage.setItem(key, write(value))
  },
  clear: () => {
    localStorage.removeItem(key)
  },
})

export const tokenStorage = getStorage<OAuth2Token | null>(
  'token-store',
  () => null,
)

export const updatesStorage = getStorage<number>('lastUpdatesIndex', () => -1)

export const updatesStorageGuest = getStorage<number>(
  'lastUpdatesIndexGuest',
  () => -1,
)
