import { getStorageBoolRef } from '~/services/stores'

/**
 * stored ref for guest role
 * undefined: not yet initialized (defaults to true)
 * true: Guest role
 * false: Engineering Team Member role
 */
export const isGuest = getStorageBoolRef('isGuest', true)

export const toggleRole = () => {
  isGuest.value = !isGuest.value
  // reload the page to apply the new role
  window.location.reload()
}
