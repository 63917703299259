import { CFG } from '~/services/cfg'
import { getIssue, updateDescription } from '~/api/issues'

// https://gitlab.com/kampaay/general/-/issues/3075
export const SPRINTS_CFG_ISSUE_ID = 3075

// https://gitlab.com/kampaay/general/-/issues/2734
export const SPRINTS_RESULTS_ISSUE_ID = 2734

// https://gitlab.com/kampaay/general/-/issues/3054
export const PLANNING_ISSUE_ORDER = 3054

// https://gitlab.com/kampaay/general/-/issues/3095
export const SEEDS_ISSUE_ORDER = 3095

// https://gitlab.com/kampaay/general/-/issues/3234
export const CANDIDATE_SPRINT_ISSUES = 3234

const generalProjectId = CFG.projects.general.id

export class SpecialIssue<TEntity extends object> {
  protected _data?: string
  private _fetch
  constructor(private issueId: number) {
    this._fetch = singletonFactory(
      async () => (await getIssue(generalProjectId, issueId)).description!,
    )
  }
  protected async _load(force = false) {
    if (force) {
      this._data = await this._fetch.force()
    } else if (!this._data) {
      this._data = await this._fetch()
    }
    return this.data
  }
  protected _parse(serialized: string) {
    return JSON.parse(serialized) as TEntity
  }
  protected _write(data: TEntity) {
    return JSON.stringify(data)
  }
  protected get data() {
    // side effect implicit load
    if (this._data === undefined) {
      this._load()
    } else {
      return this._parse(this._data)
    }
  }
  /**
   * returns updated data after applying the updaterOrValue (if function), otherwise the value itself
   * in case of function, the current data could be passed as an argument
   * in that case it will not force the refetch of the data
   * @param updaterOrValue
   * @param current
   * @returns
   */
  private async getDataToBeSaved(
    updaterOrValue: TEntity | ((data: TEntity) => TEntity),
    current?: TEntity,
  ) {
    if (typeof updaterOrValue === 'function') {
      return updaterOrValue(
        (current ??= this._parse(await this._fetch.force())),
      )
    }
    return updaterOrValue
  }
  protected async _save(
    updaterOrValue: TEntity | ((data: TEntity) => TEntity),
  ) {
    // reducing concurrency problems: we avoid overriding the whole payload with data we fetched time ago.
    // We fetch the most recent version and update only the specific part

    // optimistic update (no force fetch)
    this._data = this._write(
      await this.getDataToBeSaved(updaterOrValue, this.data),
    )

    // real update: forces re-fetch
    const data = await this.getDataToBeSaved(updaterOrValue)

    this._data = (
      await updateDescription(generalProjectId, this.issueId, this._write(data))
    ).description!
    return this.data!
  }
}
