import revive_payload_client_SjffiQtX9U from "/builds/kampaay/scrum/node_modules/.pnpm/nuxt@3.6.1_@types+node@18.16.19_sass@1.63.6_typescript@5.1.6_vue-tsc@1.8.4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_SyMvOERyc2 from "/builds/kampaay/scrum/node_modules/.pnpm/nuxt@3.6.1_@types+node@18.16.19_sass@1.63.6_typescript@5.1.6_vue-tsc@1.8.4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import components_plugin_KR1HBZs4kY from "/builds/kampaay/scrum/.nuxt/components.plugin.mjs";
import unhead_VwRAXRR8iG from "/builds/kampaay/scrum/node_modules/.pnpm/nuxt@3.6.1_@types+node@18.16.19_sass@1.63.6_typescript@5.1.6_vue-tsc@1.8.4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_u24F9Ireje from "/builds/kampaay/scrum/node_modules/.pnpm/nuxt@3.6.1_@types+node@18.16.19_sass@1.63.6_typescript@5.1.6_vue-tsc@1.8.4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_6RztGtuJoJ from "/builds/kampaay/scrum/node_modules/.pnpm/nuxt@3.6.1_@types+node@18.16.19_sass@1.63.6_typescript@5.1.6_vue-tsc@1.8.4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import payload_client_qerTpOBW5n from "/builds/kampaay/scrum/node_modules/.pnpm/nuxt@3.6.1_@types+node@18.16.19_sass@1.63.6_typescript@5.1.6_vue-tsc@1.8.4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import _1_extensions_DXLq8Vce8i from "/builds/kampaay/scrum/plugins/1.extensions.ts";
import _2_vuetify_Hdwv10Z9Ys from "/builds/kampaay/scrum/plugins/2.vuetify.ts";
import _3_queryString_BGL040dd6b from "/builds/kampaay/scrum/plugins/3.queryString.ts";
export default [
  revive_payload_client_SjffiQtX9U,
  router_SyMvOERyc2,
  components_plugin_KR1HBZs4kY,
  unhead_VwRAXRR8iG,
  prefetch_client_u24F9Ireje,
  chunk_reload_client_6RztGtuJoJ,
  payload_client_qerTpOBW5n,
  _1_extensions_DXLq8Vce8i,
  _2_vuetify_Hdwv10Z9Ys,
  _3_queryString_BGL040dd6b
]