import { type KeyName } from '~/services/keys'
import { type RouteName, getQsValue } from '~/services/routing'
import type { Router, LocationQuery } from 'vue-router'
import { withPlugins } from '~/utils/hooks/refs'
import { getStorage } from '~/services/storage'
import type { ObservedRoutes } from '../3.queryString'

export const pushWithState = (() => {
  let isPushing = false
  return {
    get isPushing() {
      return isPushing
    },
    /**
     * This method is used to route to another page through a deeplink.
     * In this case, the QS is used to override (part of) the state of the page we are navigating to.
     * NOTE: localStorage is not necessarily synced with the state we are injecting in the QS
     * (see `getQsStorageRef` for more details).
     * @param router
     * @param pathName
     * @param qsState
     */
    async push(
      router: Router,
      pathName: RouteName,
      qsState: Partial<Record<KeyName, object>>,
    ) {
      const querifyed = Object.fromEntries(
        Object.entries(qsState).map(([key, value]) => [
          key,
          JSON.stringify(value),
        ]),
      )
      isPushing = true
      await router.push({
        name: pathName,
        query: {
          ...querifyed,
        },
      })
      isPushing = false
    },
  }
})()

/**
 * creates a Ref<T> that is stored and synchronized with local storage and the url (in the query string)
 * The wrapper it returns SHOULD be registered in the observedRoutes array to make it properly work.
 * @param activeOnRoute
 * @param keyName
 * @param getDefaultValue
 * @param parse
 * @param write
 * @returns
 */
export const buildUseQsStorageRef = <T>(
  activeOnRoute: RouteName,
  keyName: KeyName,
  getDefaultValue: () => T,
  parse: (serialized: string) => T = (serialized) => JSON.parse(serialized),
  write: (value: T) => string = (value) => JSON.stringify(value),
) => {
  const storage = getStorage<T>(keyName, getDefaultValue, parse, write)
  let refInstance: Ref<T> | undefined
  const wrapper = {
    getRef(router: Router) {
      if (!refInstance) {
        refInstance = withPlugins(
          () => {
            const serialized = getQsValue(
              router.currentRoute.value.query,
              keyName,
            )
            return serialized ? parse(serialized) : storage.get()
          },
          {
            onChange(value: T, isInit: boolean) {
              const route = router.currentRoute.value
              // in case of push from another page we want to update the ref with the state contained in the QS
              // no changes in localStorage
              // TODO: should this persist across user changes or just for the first landing?
              if (!isInit && !pushWithState.isPushing) {
                storage.set(value)
                if (route.name === activeOnRoute) {
                  // in case of user value update, we want to keep QS in sync
                  // TODO: should it be debounced? should it be a push instead of replace?
                  router.replace({
                    query: {
                      ...route.query,
                      [keyName]: write(value),
                    },
                  })
                }
              }
            },
          },
        )
      }
      return refInstance
    },
    activeOnRoute,
    keyName,
    syncWithQs(router: Router, query: LocationQuery) {
      const serialized = getQsValue(query, keyName)
      const store = this.getRef(router)
      if (serialized) store.value = parse(serialized)
    },
    getSerialized(router: Router) {
      const store = this.getRef(router)
      return write(store.value)
    },
  }
  return {
    wrapper,
    useRef: () => {
      if (!refInstance)
        throw new Error(`ref not initialized for keyName ${keyName}`)
      return refInstance
    },
  }
}

export const attachRouterHooks = (
  router: Router,
  observedRoutes: ObservedRoutes,
) => {
  let isLanding = true

  // for any page change, sync QS with ref value
  // it can restore the state from the QS (deeplinking) or
  // update the QS with the current state (to keep ref and QS in sync).
  router.beforeEach((to, from) => {
    if (to.name !== from.name || isLanding) {
      isLanding = false
      const handlers = observedRoutes.filter((h) => h.activeOnRoute === to.name)
      if (handlers.length) {
        if (pushWithState.isPushing) {
          // in case of push from another page we want to update the ref with the state contained in the QS
          // with no changes in localStorage
          Object.values(handlers).forEach((h) => h.syncWithQs(router, to.query))
        } else {
          // in case of navigation, we want to re-sync QS with active refs
          const aggregate = Object.fromEntries(
            handlers.map((h) => [h.keyName, h.getSerialized(router)]),
          )
          // we replace the QS just if there are differences
          if (
            Object.keys(aggregate).some((k) => to.query[k] !== aggregate[k]) &&
            to.name !== null
          ) {
            return {
              name: to.name,
              query: {
                ...to.query,
                ...aggregate,
              },
            }
          }
        }
      }
    }
  })
}

/**
- register keyName & activeOnRoute permette inizializzare ref quando richiesta (da router o da componente)
- ref è istanziata in maniera lazy, solo quando richiesta
- middleware permette di sincronizzare QS con ref
- ref con plugin sincronizza ref con QS (se non è inizializzazione, caso gestito invece dal router stesso)
- il builder del singleton crea ref con onChange reattivo solo la seconda volta (!isInit) se la modifica 
non è dovuta a un pushWithState
 */
