import dayjs, { Dayjs } from 'dayjs'
import { SprintIssueR, SprintIssue } from '~/api/issues/models'
import { getDayBoundaries } from '~/components/burndown/utils'
import { getDevDomain, getUserByGlId } from '~/services/cfg/cfg'
import { DevStatsR, type TDevStats } from './DevStats'
import { getPf } from '~/services/cfg/perf'
import { bugsHub } from '~/api/issues/models/BugsHub'
import { AggregateStatsR } from './AggregateStats'
import { ReleaseStatsR } from './ReleaseStats'
import { LoadStatsR, buildStatsAggregate } from './LoadStats'
import { sprintsHub } from './SprintsHub'
import { glGetAll } from '~/api/common'
import { CFG } from '~/services/cfg'
import { UNPLANNED_START_COUNT } from '..'
import { sprintResults } from '~/api/issues/models/specialIssues/SprintResults'
import { sprintCfg } from '~/api/issues/models/specialIssues/SprintCfg'

const generalProjectId = CFG.projects.general.id

const loadSprintIssues = singletonFactory(
  async (sprint: Sprint, milestoneId: number) =>
    (await getMilestoneIssues(milestoneId)).map((m) => SprintIssueR(m, sprint)),
  (_sprint, id) => id.toString(),
)

export const getMilestoneIssues = async (milestoneId: number) =>
  glGetAll<GLIssue[]>(
    generalProjectId.toString(),
    `milestones/${milestoneId}/issues`,
  )

// TODO: refactor this in order to have an individual results computed and a resultPayload computed
// from results: results should be used to show full information about individual progress,
// while resultPayload just for saving the payload: think a bit about this and where we can use
// it for live results of current sprint.
const getResultBuilder = (issues: SprintIssue[]) => (devId: number) => {
  const user = getUserByGlId(devId)!
  const userRelatedGenIssues = issues.filter(
    (i) =>
      !!i.techIssues?.some((ti) => ti.assignee?.username === user.glUsername),
  )
  const inaccurateIssues = userRelatedGenIssues
    .filter((i) => i.inaccuracy > 0)
    .map((i) => i.iid)

  const releasedTech = userRelatedGenIssues
    .filter((i) => i.isReleased)
    .flatMap(
      (i) =>
        i.techIssues?.filter(
          (ti) => ti.assignee?.username === user.glUsername,
        ) ?? [],
    )
  const unreleasedTech = userRelatedGenIssues
    .filter((i) => !i.isReleased)
    .flatMap(
      (i) =>
        i.techIssues?.filter(
          (ti) => ti.assignee?.username === user.glUsername,
        ) ?? [],
    )

  const budget = releasedTech
    .filter((i) => i.isBudget)
    .sum((i) => i.deliveredWeight)
  const deliveredSpent = releasedTech.sum((i) => i.sprintSpentSP)
  const delivered = releasedTech.sum((i) => i.deliveredWeight)
  const undeliveredSpent = unreleasedTech.sum((i) => i.sprintSpentSP)
  const totalSpent = deliveredSpent + undeliveredSpent
  const fastTrackDelivered = releasedTech.sum((i) =>
    i.isFastTrack ? i.sprintStartWeight : 0,
  )

  const devDomain = getDevDomain(devId)
  const devRAndDSpent = [...releasedTech, ...unreleasedTech].sum((i) =>
    i.domain && i.domain !== devDomain ? i.sprintSpentSP : 0,
  )

  return {
    delivered,
    deliveredSpent,
    totalSpent,
    budget,
    inaccurateIssues,
    fastTrackDelivered,
    devRAndDSpent,
  }
}
export class Sprint {
  protected _issues?: SprintIssue[]

  constructor(
    private fromApi: GLMilestone,
    public isUnallocated = false,
  ) {}

  get techIssues() {
    return this.issues?.flatMap((i) => i.techIssues ?? []) ?? []
  }

  get state() {
    return this.fromApi.state
  }

  get id() {
    return this.fromApi.id
  }

  get iid() {
    return this.fromApi.iid
  }

  get title() {
    return this.fromApi.title
  }

  private get cfg() {
    return sprintCfg.cfgs?.[this.number]
  }

  get teams() {
    return this.cfg?.teams
  }
  get sprintDates() {
    return this.cfg?.sprintDates.sort((a, b) => a.diff(b))
  }
  get isFuture() {
    return !this.startDate || this.startDate?.isFuture()
  }

  get startDate() {
    return this.sprintDates?.first()
  }

  get endDate() {
    return this.sprintDates?.last()
  }

  get length() {
    return this.sprintDates?.length ?? 0
  }

  /**
   * Sprint length in days
   */
  get sprintLength() {
    return this.sprintDates?.length ?? 0
  }

  async loadIssues(): Promise<SprintIssue[]> {
    const issues = await loadSprintIssues(this, this.id)
    if (!this._issues) this._issues = issues
    return this._issues
  }

  // override to return correct type
  /**
   * Returns all OPEN issues in the sprint
   */
  get issues() {
    // side effect implicit load
    this.loadIssues()
    return this._issues?.filter((i) => !i.isClosed)
  }

  /**
   * Returns all CLOSED issues in the sprint
   */
  get closedIssues() {
    // side effect implicit load
    this.loadIssues()
    return this._issues?.filter((i) => i.isClosed)
  }

  saveSprintCfg(teams: TeamsCfg, sprintDates: Dayjs[]) {
    return sprintCfg.saveSprintCfg(this.number, teams, sprintDates)
  }

  get hasResults() {
    return (
      this.resultsSnapshot !== undefined &&
      !!Object.keys(this.resultsSnapshot.individual).length
    )
  }

  hasTeams(): this is WithRequired<Sprint, 'teams'> {
    return this.teams !== undefined
  }

  // #region results management
  get currentResults(): GLSprintResults {
    const devIds = Object.values(this.teams!).flatMap((team) =>
      team.FErs.concat(team.BErs).map((dev) => dev.glId),
    )

    const issues = this.issues ?? []

    const withInaccuracy = issues
      .filter((i) => i.inaccuracy > 0)
      .reduce(
        (acc, curr) => ({ ...acc, [curr.iid]: curr.inaccuracy }),
        {} as Record<number, number>,
      )

    const getResult = getResultBuilder(issues)
    return {
      individual: devIds.reduce(
        (acc, id) => ({ ...acc, [id]: getResult(id) }),
        {} as Record<number, GLSprintResult>,
      ),
      issues: withInaccuracy,
    }
  }
  get resultsSnapshot() {
    return sprintResults.data?.[this.number]
  }

  /**
   * returns snapshot results if available, otherwise current results (still open sprint use case)
   */
  get currentOrSnapshotResults(): GLSprintResults {
    return this.hasResults ? this.resultsSnapshot! : this.currentResults
  }

  get areResultsUpToDate() {
    return (
      this.resultsSnapshot &&
      deepEqual(this.currentResults, this.resultsSnapshot)
    )
  }
  saveResults() {
    return sprintResults.save(this.number, this.currentResults)
  }

  /**
   * returns bugs reported by other people than devs in the sprint
   */
  get bugsReportedByPos() {
    const { firstDay, lastDay } = this
    if (!firstDay || !lastDay) return []
    return (
      bugsHub.bugs?.filter(
        (b) =>
          b.createdAt.isBetween(firstDay, lastDay) &&
          !this.devIds.includes(b.author.id),
      ) ?? []
    )
  }

  get devs() {
    const teams = this.teams ? Object.values(this.teams) : []
    return teams.flatMap((t) => t.FErs.concat(t.BErs))
  }

  get devIds() {
    return this.devs.map((dev) => dev.glId)
  }

  get devStats(): TDevStats[] {
    const sprint = this
    if (sprint.hasTeams()) return this.devs.map((dev) => DevStatsR(sprint, dev))
    return []
  }

  get inaccurateIssuesWeight() {
    return this.currentOrSnapshotResults.issues
  }

  readonly sprintStats = AggregateStatsR(this)

  // #endregion results management

  get perfRatio() {
    if (!this.firstDay) return undefined
    return getPf(this.firstDay)
  }

  // TODO: temporary - just a corrective action for Q3 devPlans
  get sdDeltaInpact() {
    // compute the impact of SD on the sprint considering Marco Ottone estimation:
    // from sprint 13 on, we triled time spent on solution design from 1 to 3 SP / dev / sprint,
    // so we are roughly and on average investing 1 day more than before in SD.
    // Therefore we want to compute the percentage of SD time delta introduced in each sprint and remove that
    // percentage from every dev PF as a corrective action to have a fair comparison with previous sprints.
    return this.number < 13 || !this.sprintDates
      ? 0
      : 1 / this.sprintDates.length
  }

  get number() {
    return Number(this.title)
  }

  /**
   * unique sprint name for display purposes (or as unique ID)
   */
  get name() {
    return this.isUnallocated
      ? `remote #${this.number - UNPLANNED_START_COUNT + 1}`
      : `sprint #${this.number}`
  }

  /**
   * Sprint label for planning purposes.
   * This is not unique since it changes over time for each sprint.
   */
  get planningLabel() {
    return this.isUnallocated
      ? this.name
      : `sprint +${this.number - (sprintsHub.currentSprint?.number ?? 0)}`
  }

  get firstDay() {
    return this.sprintDates?.first()
  }

  get lastDay() {
    return this.sprintDates?.last()
  }

  get sprintDaysTillNow() {
    return this.sprintDates?.filter((d) => !d.isFuture('day'))
  }

  get missingSprintDays() {
    return this.sprintDates?.filter((d) => !d.isPast('day'))
  }

  get dayBoundaries() {
    return this.sprintDates?.map((_d, i) =>
      getDayBoundaries(this.sprintDates!, i),
    )
  }

  releasedStats = buildFullDimensionsTree(this, ReleaseStatsR)

  dayBelongsToSprint(day: Dayjs = dayjs()) {
    const { firstDay, lastDay } = this
    if (!firstDay || !lastDay) return false
    return day.isBetween(firstDay, lastDay, 'day')
  }

  /**
   * sets all issues and tech issues' workflow state to 'TODO'
   */
  async kickoffSprint() {
    const issues = this.issues
    if (!issues) {
      throw new Error(`No issues found for sprint #${this.number}`)
    }
    await Promise.all(issues.map((i) => i.setAllAsTodo()))
  }

  get velocityForecastAggregates() {
    const { teams } = this
    if (!teams) return undefined
    return mapValues((team) => {
      const all = team.BErs.concat(team.FErs)
      return {
        fe: team.FErs.avg(
          (dev) =>
            sprintsHub.devVelocity.find((d) => dev.glId === d.dev.glId)
              ?.velocity ?? 0,
          0,
        ),
        be: team.BErs.avg(
          (dev) =>
            sprintsHub.devVelocity.find((d) => dev.glId === d.dev.glId)
              ?.velocity ?? 0,
          0,
        ),
        all: all.avg(
          (dev) =>
            sprintsHub.devVelocity.find((d) => dev.glId === d.dev.glId)
              ?.velocity ?? 0,
          0,
        ),
      }
    }, teams)
  }

  loadStats = buildDimensionsTree(this, LoadStatsR, buildStatsAggregate)

  get issueCandidates() {
    return (
      sprintsHub.allIssues?.filter(
        (i) => i.candidate?.sprint?.number === this.number,
      ) ?? []
    ).map((i) => ({ issue: i, nth: i.candidate!.nth }))
  }
}

export const SprintR = reactifyClass(Sprint)

// const p: EnrichedSprint = SprintR({} as any)
