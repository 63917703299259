import {
  Issue,
  TechIssue,
  loadTechIssues,
  loadSeeds,
  SprintTechIssue,
  SprintTechIssueR,
  type ISprintIssueBase,
  issueR,
} from './index'
import { Sprint } from '~/api/milestones/models/Sprint'
import { type Progress } from '~/components/burndown/types'
import { Dayjs } from 'dayjs'
import { seedsHub } from '~/api/seeds/models/SeedsHub'
import { type TSeed } from '~/api/seeds/models/Seed'
import { linkIssues, deleteLink } from '..'
import { sprintsHub } from '~/api/milestones/models/SprintsHub'
import { CFG, LABELS } from '~/services/cfg'
import { sprintCandidates } from './specialIssues/SprintCandidates'

const loadIssueRelationsSemaphore = semaphoreBuilder()

const loadTechIssuesBatch = loadIssueRelationsSemaphore(
  (iid: number, sprintBelongingTo: Sprint) =>
    loadTechIssues(iid).then((tis) =>
      tis.map((ti) => SprintTechIssueR(ti, sprintBelongingTo) as TechIssue),
    ),
)

const loadSeedsIidsBatch = loadIssueRelationsSemaphore(loadSeeds)

const InSprintStatsR = reactifyClass(
  class {
    constructor(
      private issue: SprintIssue,
      private domain?: Domain,
    ) {}

    private get techIssues() {
      return this.domain
        ? this.issue.techIssues?.filter((ti) => ti.domain === this.domain)
        : this.issue.techIssues
    }

    get sprintStartWeight() {
      return this.techIssues?.sum((ti) => ti.sprintStartWeight) ?? 0
    }

    get sprintSpentSP() {
      return this.techIssues?.sum((ti) => ti.sprintSpentSP ?? 0) ?? 0
    }

    get sprintSpentEvents() {
      return this.techIssues?.flatMap((ti) => ti.sprintSpentEvents ?? []) ?? []
    }

    get sprintWeightEvents() {
      return this.techIssues?.flatMap((ti) => ti.sprintWeightEvents)
    }

    get dailyUpdates() {
      return this.issue.sprint.sprintDaysTillNow?.map((d, i) => ({
        weightEvents:
          this.techIssues?.flatMap(
            (ti) => ti.dailyUpdates?.[i]!.weightEvents,
          ) ?? [],
        spentEvents:
          this.techIssues?.flatMap((ti) => ti.dailyUpdates?.[i]!.spentEvents) ??
          [],
        assignmentEvents:
          this.techIssues?.flatMap(
            (ti) => ti.dailyUpdates?.[i]!.assignmentEvents,
          ) ?? [],
        weight:
          this.techIssues?.sum((ti) => ti.dailyUpdates?.[i]!.weight ?? 0) ?? 0,
        spent:
          this.techIssues?.sum((ti) => ti.dailyUpdates?.[i]!.spent ?? 0) ?? 0,
        assignment: undefined,
        delta:
          this.techIssues?.sum((ti) => ti.dailyUpdates?.[i]!.delta ?? 0) ?? 0,
      }))
    }

    get progress() {
      return SprintIssue.getProgressStatus(
        this.sprintStartWeight,
        this.sprintSpentSP,
        this.issue.weight,
      )
    }

    get eta() {
      return this.techIssues?.firstBy(
        (i1, i2) =>
          i1.eta === undefined ||
          (i2.eta !== undefined && i1.eta.isAfter(i2.eta)),
      )?.eta
    }
  },
)

const InSprintStatsAggregateR = reactifyClass(
  class BurndownAggregate {
    constructor(
      private domain: Domain | 'all',
      private issues: SprintIssue[],
      private pastDays: Dayjs[],
    ) {}

    get spentSp() {
      return this.issues.sum(
        (issue) => issue.inSprintStats[this.domain].sprintSpentSP,
      )
    }

    get sprintStartWeight() {
      return this.issues.sum(
        (issue) => issue.inSprintStats[this.domain].sprintStartWeight,
      )
    }

    get weight() {
      return this.issues.sum((issue) => issue.stats[this.domain].weight)
    }

    get dailyAggregate() {
      return this.pastDays.map((_d, i) => ({
        weight: this.issues.sum(
          (issue) =>
            issue.inSprintStats[this.domain].dailyUpdates?.[i]!.weight ?? 0,
        ),
        spent: this.issues.sum(
          (issue) =>
            issue.inSprintStats[this.domain].dailyUpdates?.[i]!.spent ?? 0,
        ),
        delta: this.issues.sum(
          (issue) =>
            issue.inSprintStats[this.domain].dailyUpdates?.[i]!.delta ?? 0,
        ),
      }))
    }

    get progress() {
      return SprintIssue.getProgressStatus(
        this.sprintStartWeight,
        this.spentSp,
        this.weight,
      )
    }
  },
)

const techIssuesByDomain = reactifyClass(
  class {
    constructor(
      private issue: SprintIssue,
      private domain?: Domain | 'all',
    ) {}
    get collection() {
      return this.domain === 'all'
        ? this.issue.techIssues
        : this.issue.techIssues?.filter((ti) => ti.domain === this.domain)
    }

    get areTISized() {
      return !!this.collection?.every((ti) => ti.weight !== undefined)
    }

    get areTIEstimated() {
      return !!this.collection?.every((ti) => ti.isEstimated)
    }
  },
)

/**
 * A sprint issue is an issue that belongs to a sprint
 * and it is aware of it, so it can compute stats
 * based on inner data and on the sprint data (config, etc.)
 */
export class SprintIssue extends Issue implements ISprintIssueBase {
  sprint: Sprint
  private _seeds?: GLIssueLink[]

  constructor(fromApi: GLIssue, sprintBelongingTo: Sprint) {
    super(fromApi)
    this.sprint = sprintBelongingTo
  }

  get isWorkable() {
    return this.hasTechIssues && this.techIssuesByDomain['all'].areTIEstimated
  }

  get planningWfState() {
    const state = this.workflowState
    const workableCandidates = [
      LABELS.roadmapReady,
      LABELS.design,
      LABELS.analysis,
    ]
    const isFromRoadmapReady =
      state && (workableCandidates as string[]).includes(state)
    if (isFromRoadmapReady)
      return this.isWorkable ? LABELS.workable : LABELS.roadmapReady
    return state
  }

  /**
   * tech blocks: tech tasks to be done before some of this issue's tech tasks can be started
   */
  get techBlockedBy() {
    if (!this._techIssues || this._techIssues.some((ti) => !ti.blockedByLinks))
      return undefined
    return this._techIssues.flatMap((ti) => ti.blockedByLinks!)
  }

  /**
   * master issues depending on this issue
   */
  get blocks() {
    return (
      sprintsHub.futureIssues?.filter(
        (i) => i.blockedByLinks?.find((i) => i.iid === this.iid),
      ) ?? []
    )
  }

  private get blockedByIids() {
    return this.blockedByLinks?.map((i) => i.iid)
  }

  get blockedByPast() {
    const { blockedByIids } = this
    return !!blockedByIids && !!sprintsHub.pastIssues
      ? sprintsHub.pastIssues.filter((i) => blockedByIids.includes(i.iid))
      : []
  }

  get blockedByCurrent() {
    const { blockedByIids } = this
    return !!blockedByIids && !!sprintsHub.currentSprintIssues
      ? sprintsHub.currentSprintIssues.filter((i) =>
          blockedByIids.includes(i.iid),
        )
      : []
  }

  get blockedByFuture() {
    const { blockedByIids } = this
    return !!blockedByIids && !!sprintsHub.futureIssues
      ? sprintsHub.futureIssues.filter((i) => blockedByIids.includes(i.iid))
      : []
  }

  get blockedBy() {
    return [
      ...this.blockedByPast,
      ...this.blockedByCurrent,
      ...this.blockedByFuture,
    ]
  }

  /**
   * when true for a master, the activity is just a reference without tech issues
   */
  get isContainerOnly() {
    return !!this.customData.containerOnly
  }

  async setContainerOnly(containerOnly: boolean) {
    return this.setCustomData('containerOnly', containerOnly)
  }

  get isMaster() {
    return !!this.customData.isMaster
  }

  get isSlave() {
    return this.blocks.length > 0
  }

  /**
   * @param isMasterOrChildren set as master without dependencies (true or undefined),
   * or with dependencies iids (number[]) or unset as master (false)
   */
  async setMaster(isMasterOrChildren: boolean | number[] = true) {
    const isMaster = !!isMasterOrChildren
    const promises: Promise<unknown>[] = []
    if (this.isMaster !== isMaster) {
      promises.push(this.setCustomData('isMaster', isMaster))
    }
    // compute dependencies to be removed or added
    const newDeps =
      isMasterOrChildren instanceof Array ? isMasterOrChildren : []

    // we just deal with general issues, leaving untouched other relations
    const oldDeps =
      this.blockedByLinks
        ?.filter((i) => i.project_id === CFG.projects.general.id)
        .map((i) => i.iid) ?? []
    const toBeAdded = newDeps.filter((i) => !oldDeps.includes(i))
    const toBeRemoved =
      this.blockedByLinks
        ?.filter(
          (i) =>
            i.project_id === CFG.projects.general.id &&
            !newDeps.includes(i.iid),
        )
        .map((i) => i.issue_link_id) ?? []

    // add dependencies
    promises.push(
      ...toBeAdded.map((iid) =>
        linkIssues(
          CFG.projects.general.id,
          this.iid,
          CFG.projects.general.id,
          iid,
          'is_blocked_by',
        ),
      ),
    )

    // remove dependencies
    promises.push(
      ...toBeRemoved.map((iid) =>
        deleteLink(CFG.projects.general.id, this.iid, iid),
      ),
    )

    await Promise.all(promises)

    // since "create an issue link" does not return issue_link_id that is needed to delete it later, if needed
    // we need to reload all blockedBy
    await this.loadBlocking(true)
  }

  /**
   * shortcut to the assigned sprint last day
   * TODO: it should always be defined, no sprint object without end date
   * should be allowed by design
   *
   */
  get releaseDate() {
    return this.sprint.lastDay
  }

  async loadSeeds() {
    const seeds = await loadSeedsIidsBatch(this.iid)
    if (this._seeds === undefined) {
      this._seeds = seeds
    }
    return seeds
  }

  get seeds() {
    // side effect implicit load
    this.loadSeeds()
    return (
      seedsHub.seeds?.filter(
        (s) => this._seeds?.map((s) => s.iid).includes(s.iid),
      ) ?? []
    )
  }

  async setSeeds(seeds: TSeed[]) {
    const actual = this._seeds
    if (actual === undefined) throw new Error('Seeds not loaded yet')
    // compute relatedTo links to be deleted and links to be created
    const actualLinksIids = actual.map((s) => s.iid)
    const newLinksIids = seeds.map((s) => s.iid)
    const toDelete = actual.filter((s) => !newLinksIids.includes(s.iid))
    const toCreate = newLinksIids.filter(
      (iid) => !actualLinksIids.includes(iid),
    )

    const deletePromise = Promise.all(
      toDelete.map((s) =>
        deleteLink(this.projectId, this.iid, s.issue_link_id),
      ),
    )
    const createPromise = Promise.all(
      toCreate.map((iid) =>
        linkIssues(this.projectId, this.iid, this.projectId, iid),
      ),
    )
    await Promise.all([createPromise, deletePromise])

    // NO optimistic update, reload of seeds.
    this._seeds = await loadSeeds(this.iid, true)
  }

  async loadTechIssues() {
    const issues = await loadTechIssuesBatch(this.iid, this.sprint)
    if (this._techIssues === undefined) {
      this._techIssues = issues
    }
    return this._techIssues
  }

  readonly inSprintStats = {
    be: InSprintStatsR(this, 'be'),
    fe: InSprintStatsR(this, 'fe'),
    all: InSprintStatsR(this),
  }

  get fastTrackTechIssues() {
    return this.techIssues?.filter((ti) => ti.isFastTrack) ?? []
  }

  // shortcut
  get eta() {
    return this.inSprintStats.all.eta
  }

  // shortcut
  get sprintSpentSP() {
    return this.inSprintStats.all.sprintSpentSP
  }

  // shortcut
  get progress() {
    return this.inSprintStats.all.progress
  }

  get sprintStartWeight() {
    return this.inSprintStats.all.sprintStartWeight
  }

  get sprintSpentEvents() {
    return this.inSprintStats.all.sprintSpentEvents
  }

  // override to return correct type
  /**
   * Returns all related OPEN t. issues
   */
  get techIssues() {
    // side effect implicit load
    this.loadTechIssues()
    return this._techIssues?.filter((i) => !i.isClosed) as
      | SprintTechIssue[]
      | undefined
  }

  /**
   * Returns all related CLOSED t. issues
   */
  get closedTechIssues() {
    // side effect implicit load
    this.loadTechIssues()
    return this._techIssues?.filter((i) => i.isClosed) as
      | SprintTechIssue[]
      | undefined
  }

  get dailyUpdates() {
    return this.sprint.sprintDaysTillNow?.map((d, i) => ({
      weightEvents:
        this.techIssues?.flatMap(
          (ti) => ti.dailyUpdates?.[i]!.weightEvents ?? [],
        ) ?? [],
      spentEvents:
        this.techIssues?.flatMap(
          (ti) => ti.dailyUpdates?.[i]!.spentEvents ?? [],
        ) ?? [],
      assignmentEvents:
        this.techIssues?.flatMap(
          (ti) => ti.dailyUpdates?.[i]!.assignmentEvents ?? [],
        ) ?? [],
      weight:
        this.techIssues?.sum((ti) => ti.dailyUpdates?.[i]!.weight ?? 0) ?? 0,
      spent:
        this.techIssues?.sum((ti) => ti.dailyUpdates?.[i]!.spent ?? 0) ?? 0,
      assignment: undefined,
      delta:
        this.techIssues?.sum((ti) => ti.dailyUpdates?.[i]!.delta ?? 0) ?? 0,
    }))
  }

  techIssuesByDomain = {
    be: techIssuesByDomain(this, 'be'),
    fe: techIssuesByDomain(this, 'fe'),
    all: techIssuesByDomain(this, 'all'),
    noDomain: techIssuesByDomain(this),
  }

  /**
   * assigns a different sprint and updates the issue accordingly
   * @param targetSprint
   */
  async assignSprint(targetSprint: Sprint) {
    await super.assignSprint(targetSprint)
    this.sprint = targetSprint
  }
  /**
   * NOTE: after unassigning it, the current issue should be dismissed and
   * a new one (Issue) is returned
   * @returns the issue with the sprint removed
   */
  async unassignSprint() {
    await super.assignSprint()
    return issueR(this._fromApi)
  }

  static getProgressStatus(
    startWeight: number,
    spent: number,
    currentWeight: number,
  ): Progress {
    const expectedTotal = currentWeight + spent
    const progressDelta = expectedTotal - startWeight
    const progressDeltaPerc =
      startWeight === 0 ? undefined : progressDelta / startWeight
    const progressStatus =
      progressDeltaPerc === undefined
        ? progressDelta > 0
          ? 'danger'
          : 'ok'
        : progressDeltaPerc > 0.3
        ? 'danger'
        : progressDeltaPerc > 0
        ? 'warning'
        : progressDeltaPerc < 0
        ? 'wow'
        : 'ok'

    return {
      status: progressStatus,
      delta: progressDelta,
      deltaPerc: progressDeltaPerc,
    }
  }

  static computeAggregate(issues: SprintIssue[]) {
    const pastDays = issues.first()?.sprint.sprintDaysTillNow ?? []
    return {
      all: InSprintStatsAggregateR('all', issues, pastDays),
      be: InSprintStatsAggregateR('be', issues, pastDays),
      fe: InSprintStatsAggregateR('fe', issues, pastDays),
    }
  }

  /**
   * return candidacy data (sprint, nth flag) if candidated
   */
  get candidate() {
    const candidate = sprintCandidates.issues?.findByIid(this.iid)
    if (candidate) {
      const { iid, nth, sprintNumber } = candidate
      const sprint = sprintsHub.sprintsAndUnplanned?.find(
        (s) => s.number === sprintNumber,
      )
      return {
        iid,
        nth,
        sprint,
      }
    }
  }
  /**
   * candidates the issue to a sprint if sprintNumber is provided, otherwise deletes its candidacy
   * @param sprintNumber
   */
  candidateAssign(sprintNumber?: number) {
    sprintCandidates.setAssignation(this.iid, sprintNumber)
  }
  /**
   * toggle candidacy nth flag
   */
  candidateToggleNth() {
    sprintCandidates.toggleNth(this.iid)
  }
}

export type IssueAggregateStats = NonNullable<
  ReturnType<typeof SprintIssue.computeAggregate>
>

export const SprintIssueR = reactifyClass(SprintIssue)
export type SprintIssueI = InstanceType<typeof SprintIssue>
