import { withPlugins } from '~/utils/hooks/refs'
import { getStorage, type KeyName } from '../storage'

/**
 * creates a ref that is stored and synchronized with local storage when updated
 * allowing for persistence of state across sessions
 * @param key
 * @param write
 * @param parse
 * @returns
 */
export const getStorageRef = <T>(
  keyName: KeyName,
  getDefaultValue: () => T,
  parse: (serialized: string) => T = (serialized) => JSON.parse(serialized),
  write: (value: T) => string = (value) => JSON.stringify(value),
) => {
  const storage = getStorage<T>(keyName, getDefaultValue, parse, write)
  return withPlugins(() => storage.get(), {
    onChange: (value, isInit) => isInit || storage.set(value),
  })
}

/**
 * creates a ref that is stored and synchronized with local storage when updated
 * defaults to false (extend to allow a different default value)
 * @param key
 * @returns
 */
export const getStorageBoolRef = (keyName: KeyName, defaultValue = false) =>
  getStorageRef(
    keyName,
    () => defaultValue,
    (serialized) => serialized === 'true',
  )
