export const withIidArrayExtensions = {
  findByIid<T extends WithIid>(this: T[], iid: number): T | undefined {
    return this.find((n) => n.iid === iid)
  },
  distinctByIid<T extends WithIid>(this: T[]): T[] {
    return this.filter((e, i, a) => a.findIndex((e2) => e2.iid === e.iid) === i)
  },
  orderByIid<T extends WithIid>(this: T[], order: number[]): T[] {
    const self = [...this]
    const found: T[] = []
    order.forEach((iid) => {
      const idx = self.findIndex((e) => e.iid === iid)
      if (idx !== -1) {
        // removes the element from self and adds it to found
        found.push(self.splice(idx, 1)[0]!)
      }
    })
    return [...found, ...self]
  },
  replaceByIid<T extends WithIid>(this: T[], items: T[]) {
    // const result = [...this]

    const found = items
      .map((item) => this.replace(item, (n) => n.iid === item.iid))
      .some((found) => found)

    return found
  },
  removeByIid<T extends WithIid>(this: T[], iid: number) {
    return this.removeBy((n) => n.iid === iid)
  },
}
