<template>
  <v-btn
    v-for="route in allowedRoutes"
    :disabled="route.isCurrent"
    @click="router.push(route.path)"
  >
    {{ route.label }}
  </v-btn>
</template>

<script setup lang="ts">
import { guestRoutes, routeMap } from '~/services/routing'
import { isGuest } from '~/services/guestManagement'

const router = useRouter()
defineComponent({ name: 'RoutingBtns' })

const currentRoute = useRoute()

const allowedRoutes = computed(() => {
  const allowed = isGuest.value ? guestRoutes : keys(routeMap)
  return allowed.map((r) => ({
    ...routeMap[r],
    isCurrent: currentRoute.name === r,
  }))
})
</script>
